import React from 'react'


import Seo from '../components/seo'
import Layout from '../components/layout'
import Hero from '../components/hero'
import { ThemeProvider } from '../components/theme-context'
import { StaticImage } from "gatsby-plugin-image"

class PortfolioIndex extends React.Component {
    render() {

      return (
        <div>
          <Seo title="Contact" />
          <div class="flex flex-col h-full pt-20">
          <div class="container w-full max-w-5xl mx-auto px-3 md:px-2  py-8 md:py-8">
            <div class="flex flex-col items-center ">

                <div class="w-full md:w-2/5 px-2 mb-4 md:mb-10">
                <div class="bg-white rounded overflow-hidden shadow-md hover:shadow-lg relative smooth">
                  <a href="https://youtu.be/s-uMPoDZ7yA">
                  <div className="bg-project-cosmos dark:bg-project-cosmos bg-cover bg-center h-48 md:h-48 w-full rounded-t" />
                  </a>
                </div>
                <div class="flex mt-5">
                  <a href="https://apps.apple.com/app/1608214793" class="w-1/2 mr-5">
                    <StaticImage src="../images/appstore.png" class="rounded-t object-cover" />  
                  </a>
                  <a href="https://play.google.com/store/apps/details?id=com.frezapp.cosmos&hl=en&gl=US" class="w-1/2">
                    <StaticImage src="../images/googleplay.png" class="rounded-t object-cover" />  
                  </a>
                </div>
              </div>
            
              <div class="w-full md:w-2/5 px-2 mb-4 md:mb-10">
                <div class="bg-white rounded overflow-hidden shadow-md hover:shadow-lg relative smooth">
                  <a href="https://github.com/salvadordeveloper/flutter-animation" >
                     <div className="bg-project-wallet dark:bg-project-wallet bg-cover object-cover bg-center h-48 md:h-48 w-full rounded-t" />
                  </a>
                </div>
              </div>
              <div class="w-full md:w-2/5 px-2 mb-4 md:mb-10">
                <div class="bg-white rounded overflow-hidden shadow-md hover:shadow-lg relative smooth">
                  <a href="https://github.com/salvadordeveloper/TikTok-Flutter">
                  <div className="bg-project-tiktok dark:bg-project-tiktok bg-cover object-cover bg-center h-48 md:h-48 w-full rounded-t" />
                  </a>
                </div>
              </div>
            
            </div>
           
          </div>

         
            
          </div>
        </div>
      )
    }
  }

  export default PortfolioIndex;
